/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Kocaeli ili, Darıca ilçesi, Bayramoğlu mevkii, İş Bankası Yelkenkaya Dinlenme Tesisleri içerisinde 29.K. IV.d pafta, 1113 ada, 1 parselde yer alan, Philokrene Kalesi olarak bilinen XIII. Yüzyılın sonlarına, Geç Bizans Dönemi’ne tarihlenen1 kalenin günümüze çok az bir bölümü ulaşabilmiştir. Zaman içerisinde yıkılan sur beden duvarları müdahale görerek özgün özellikleri büyük ölçüde kaybetmiştir. Kale kalıntıları içerisinde, orta burç; yakın dönemde yenilenmiş dendanlar dışında, özgün özelliklerini büyük ölçüde koruyabilmiştir. Orta burçta dağınık bir şekilde taş tuğla karışık duvar örgüsü varken, burcun her iki yanından kuzeydoğu ve kuzeybatı yöne doğru devam eden sur beden duvarlarında moloz taş örgüsü görülmekte, duvar üst kısmındaki kesme taş örgülü dendanlar ise yakın dönemde yenilenmişlerdir. Bunun dışında kale beden duvarları yüzeyinde çimentolu derz müdahaleleri görülmektedir. Doğu sur duvarının sonlandığı bölümün güney ön kısmında; mermer malzemeden 9 adet, Bizans Dönemi mimari parçalar yer almaktadır.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
